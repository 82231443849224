import TYPES from '@/types';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InvestorGoalLinkedSuccessfullyViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.link-successfully';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  product_name = '';

  location_href = '';

  investor_goal_state = this.create_investor_goal_state_manager.state;

  button_text = '';

  goToDashboard = () => {
    window.location.href = this.location_href;
  }

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }

  initialize = () => {
    this.product_name = this.investor_goal_state.associated_product;
    if (this.product_name === 'Sowos Pocket') {
      this.location_href = '/sowos-pocket';
      this.button_text = this.translate('go_to_pocket');
    } else {
      this.location_href = '/sowos-wealth';
      this.button_text = this.translate('go_to_wealth');
    }
  }
}
